import cryptoJs from 'crypto-js';
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import AuthService from '../../Authservice';
import '../Main_cnt.css'
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button'


const Customer_login = () => {
    const navigate = useNavigate()
    var Head_img = require('../../Header/Images/MBFSLogo.png');
    const [Pannumber, setpannumber] = useState('');
    const [password, setpassword] = useState('');
    const [loginErr, setLoginErr] = useState({ usernameErr: '', userPassword: '', loginerr: false, createpassword: false, modelshow: false });
    const [Modelshow, Setmodelshow] = useState(false)
    const cryptid = process.env.REACT_APP_CRYPTID

    const encryptData = (name, data) => {
        const cryp = cryptid;
        const ciphertext = cryptoJs.AES.encrypt(`${data}`, cryp).toString();
        localStorage.setItem(name, ciphertext);
    };

    const Login_customer_VRN = () => {
        const validateerr = validatePAN(Pannumber)
        console.log(validateerr)

        if (Pannumber === '' || validateerr === false) {
            setLoginErr(prevState => ({
                ...prevState,
                usernameErr: 'Please Provide Valid PanNumber'
            }));
        } else {
            setLoginErr(prevState => ({
                ...prevState,
                usernameErr: ''
            }));
        }


        if (password === '') {
            setLoginErr(prevState => ({
                ...prevState,
                userPassword: 'Please Provide Valid Password'
            }));
            return false
        } else {
            setLoginErr(prevState => ({
                ...prevState,
                userPassword: ''
            }));
        }


        const Login_obj = {
            "Pannumber": Pannumber,
            "UserPassword": password,
            "UserName": ""
        }
        if (Pannumber !== "" && password !== "" && validateerr === true) {
            AuthService.PostServiceCall('api/Login/CustomerLogin', Login_obj)
                .then((res) => {
                    console.log(res)
                    if (res && res !== undefined && res !== null) {
                        console.log(res)
                        encryptData('useridentifier', res.identifier)
                        if (res.isAuthorized === true) {
                            setLoginErr(prevState => ({
                                ...prevState,
                                loginerr: false
                            }));
                            encryptData('Usertype', res.userType)
                            encryptData('JWT', res.authToken);
                            navigate('/Customer')
                        } else {
                            setLoginErr(prevState => ({
                                ...prevState,
                                loginerr: true
                            }));

                        }
                    }
                })
        }

    };

    const validatePAN = (panNumber) => {
        const panRegex = /^[A-Za-z]{5}\d{4}[A-Za-z]$/;
        const isValidPAN = panRegex.test(panNumber);
        return isValidPAN
    };

    const Create_Password = () => {
        setLoginErr(prev => ({ ...prev, createpassword: true }))
        setpannumber('')
        setLoginErr(prevState => ({
            ...prevState,
            usernameErr: ''
        }));
    }
    const handlecreatepassword = () => {
        const validateerr = validatePAN(Pannumber)
        setLoginErr(prevState => ({
            ...prevState,
            userPassword: ''
        }));
        console.log(validateerr)
        if (Pannumber === '' || validateerr === false) {
            setLoginErr(prevState => ({
                ...prevState,
                usernameErr: 'Please Provide Valid PanNumber'
            }));
        } else {
            setLoginErr(prevState => ({
                ...prevState,
                usernameErr: ''
            }));
        }
        if (Pannumber !== "" && validateerr === true) {
            
            AuthService.GetServiceCall(`api/Login/Dosendmailcreatepassword?pannumber=${Pannumber}`)
                .then((res) => {
                    console.log(res)
                    if (res && res === 'Email send successfully') {
                        Setmodelshow(true)
                    }
                })
        }
    }
    const createpasswordcancel = () => {
        setLoginErr(prev => ({ ...prev, createpassword: false }))
        setpannumber('')
        setLoginErr(prevState => ({
            ...prevState,
            usernameErr: '', userPassword: ''
        }));
    }
    return (
        <div>

            <div className="container-fluid top_head_logos">
                <div className="top_img_dv">
                    <div className="hanburger_sym">
                        <img src={Head_img} alt="MBFS_LOGO" />
                    </div>
                </div>
            </div>
            <div className='container-fluid login_frm'>
                <div className="form_full_align">
                    <h3>Welcome Back</h3>
                    <div className="frm_skelton">
                        {
                            loginErr.createpassword === false ?
                                (
                                    <>
                                        <div className="email_dv">
                                            <label htmlFor="username" className="form-label">PanNumber</label>
                                            <input type="text" onChange={(e) => setpannumber(e.target.value)} className="form-control" id='username' value={Pannumber} />
                                            <p className='login_err'>{loginErr.usernameErr}</p>
                                        </div>
                                        <div className="email_dv">
                                            <label htmlFor="password" className="form-label">Password</label>
                                            <input type="password" onChange={(e) => setpassword(e.target.value)} className="form-control" id='password' value={password} />
                                            <p className='login_err'>{loginErr.userPassword}</p>
                                        </div>
                                        {loginErr.loginerr === true && (
                                            <p style={{ color: 'red', fontWeight: 700 }}>
                                                <i className="fa-solid fa-triangle-exclamation" style={{ color: 'rgb(255, 204, 0)', marginRight: 10 }}></i>
                                                Invalid User. Please try again!
                                            </p>
                                        )}
                                        <div className='create_an_rs'>
                                            <p onClick={Create_Password}>Reset Password/Create Password</p>
                                        </div>
                                        <div className="sign_btn_dv">
                                            <button className="sign_btn" onClick={Login_customer_VRN} id="btnSignIn">Sign In</button>
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <div className="email_dv">
                                            <label htmlFor="username" className="form-label">PanNumber</label>
                                            <input type="text" onChange={(e) => setpannumber(e.target.value)} className="form-control" id='username' value={Pannumber} />
                                            <p className='login_err'>{loginErr.usernameErr}</p>
                                        </div>
                                        <div className="sign_btn_dv" style={{ display: 'flex', gap: '10px' }}>
                                            <button className="sign_btn" onClick={handlecreatepassword} id="btnSignIn">Submit</button>
                                            <button className="sign_btn" onClick={createpasswordcancel} id="btnSignIn">Cancel</button>
                                        </div>
                                    </>
                                )
                        }
                    </div>
                </div>
            </div>

            <Modal show={Modelshow}>
                <Modal.Header>
                    <Modal.Title>Alert!</Modal.Title>
                </Modal.Header>
                <Modal.Body>Please Check Your Mail...</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => Setmodelshow(false)}>
                        Ok
                    </Button>
                </Modal.Footer>
            </Modal>
        </div >
    )
}

export default Customer_login