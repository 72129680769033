import React from 'react'
import './Header.css'
import { useNavigate } from "react-router-dom";

const Header = ({ toggle, setToggle }) => {
    var Head_img = require('../Header/Images/MBFSLogo.png');
    const navigate = useNavigate()
    const Logout = () => {
        navigate('/');
        window.location.reload();
        localStorage.clear();
    }
    return (
        <div>
            <div className='header_top'>
                <div className='header_left_sd'>
                    <div onClick={() => setToggle(!toggle)} className="hamberger_sym three_bars_top_menu ">
                        <span className="line_1"></span>
                        <span className="line_2"></span>
                        <span className="line_1"></span>
                    </div>
                    <div className='mbfs_image'>
                        <img src={Head_img} alt="MBFS_LOGO" />
                    </div>
                    {/* <div className='e_invoice_head'>
                        <p>Vahan</p>
                    </div> */}
                </div>
                <div className='end_logout_btn'>
                    <button onClick={Logout}>
                        <i className="fa-solid fa-arrow-right-from-bracket"></i>
                        <span>Logout</span>
                    </button>
                </div>
            </div>
        </div>
    )
}

export default Header