
import { Route, Routes } from 'react-router-dom'
import User_master from './User_master'
import VRN_dashboard from './VRN_dashboard'
import VRN_master from './VRN_master'



const Table_route = () => {
    return (
        <div>
            <Routes>
                <Route exact path="/VRN_master" element={<VRN_master />} />
                <Route exact path="/VRN_dashboard" element={<VRN_dashboard />} />
                <Route exact path="/User_master" element={<User_master />} />
            </Routes>
        </div >
    )
}
export default Table_route