import React from 'react'
import Buttons from './Buttons'
import './Button_cnt.css'
import { Link } from 'react-router-dom'
import AuthService from '../../Authservice'


const Button_container = () => {

    const Usertype = AuthService.decryptData('Usertype')

    return (
        <div className='button_cnt_in'>

            <p>VRN</p>
            <div className='roww'>
            <div className=' btn_in_prp'>
                    <Link to="/VRN_dashboard">
                        <Buttons
                            innertxt={"VRN Dashboard"}
                            innerclr={"03a2d6"}
                        />
                    </Link>
                </div>
                <div className=' btn_in_prp'>
                    <Link to="/VRN_master">
                        <Buttons
                            innertxt={"VRN Master"}
                            innerclr={"ff4fff"}
                        />
                    </Link>
                </div>
                <div className='btn_in_prp'>
                    <Link to="/User_master">
                        <Buttons
                            innertxt={"User Master"}
                            innerclr={"b3234b"}
                        />
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default Button_container