import React, { useState } from 'react'
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FetchCustomerdata, setPage } from '../../Redux/Slices/CustomerSlice';
import Pagination from './Pagination';
import AuthService from '../../Authservice';
import { Button, Modal } from 'react-bootstrap';

const VRN_master = () => {
    const dispatch = useDispatch();
    const { customerpagination, CustomerDateCount, CustomerDate, loading, error } = useSelector((state) => state.customer);

    useEffect(() => {
        dispatch(FetchCustomerdata());
        console.log('coming')
    }, [dispatch, customerpagination.CustomerType, customerpagination.CustomerStatus, customerpagination.search, customerpagination.Current_page]);

    const [customersearch, setcompanysearch] = useState()
    const [alerts, setalerts] = useState({ mdlone: false, mdltw: false, rejection: '', autoid: '' })

    const handlesearchCustomerstatus = () => {
        console.log(customersearch)
        dispatch(setPage({ search: customersearch }));
    }

    const vrnmasterNumbers = [];
    for (let i = 1; i <= Math.ceil(CustomerDateCount && CustomerDateCount / 10); i++) {
        vrnmasterNumbers.push(i);
    }
    const UserhandlePageChange = (pageNumber) => {
        dispatch(setPage({ Current_page: pageNumber }));
    };
    console.log(CustomerDate)
    const handlefiledownload = (value) => {
        AuthService.GetServiceCall(`api/Customer/DownloadCustomerRc?RcName=${value}`).then((res) => {
            // console.log(res);
            if (res && res !== '' && res.responseCode === "200") {
                const href = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${res.base64String}`;
                const link = document.createElement('a');
                link.href = href;
                link.download = res.fileName;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                URL.revokeObjectURL(link.href);
            }
        }).catch((err) => console.log("Error:", err));
    }
    const handleapprove = (string, vrno, text) => {
        if (string === "Approved" || (string === 'Rejected' && alerts.rejection.length > 0)) {
            AuthService.GetServiceCall(`api/Customer/Approve_Reject_customer?value=${string}&vrno=${vrno}&Rejectiontxt=${text}`).then((res) => {
                console.log(res);
                if (res === 'Success') {
                    dispatch(FetchCustomerdata());
                    setalerts(prev => ({ ...prev, mdltw: false, rejection: '' }))
                }
            }).catch((err) => console.log("Error:", err));
        } else {
            alert('Please Fill Reason For Rejection')
        }

    }
    const [MailCheckMark, setMailCheckMark] = useState([]);
    const [AllCheckMark, setAllCheckMark] = useState(false);
    const handleMailCheck = (identifier) => {
        if (identifier === "ALL") {
            if (CustomerDate) {
                CustomerDate.forEach((Inv) => {
                    if (MailCheckMark.includes(Inv.customerAutoid)) {

                        setMailCheckMark((prevMailCheckMark) =>
                            prevMailCheckMark.filter((id) => id === Inv.customerAutoid)
                        );
                    }
                    else {
                        setMailCheckMark((prevMailCheckMark) => [...prevMailCheckMark, Inv.customerAutoid]);
                    }
                });
            }
        } else {
            if (MailCheckMark.includes(identifier)) {
                setMailCheckMark((prevMailCheckMark) => prevMailCheckMark.filter((id) => id !== identifier));
            } else {
                setMailCheckMark((prevMailCheckMark) => [...prevMailCheckMark, identifier]);
            }
        }
    };
    const Handleblukdownload = () => {
        const RcName = MailCheckMark;
        if (RcName.length > 0) {
            console.log(RcName)
            AuthService.PostServiceCallToken('api/Customer/DownloadCustomerRcBulk', RcName).then((res) => {
                console.log(res);
                if (res && res !== '' && res.responseCode === "200") {
                    const href = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${res.base64String}`;
                    const link = document.createElement('a');
                    link.href = href;
                    link.download = res.fileName;
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                    URL.revokeObjectURL(link.href);
                    setMailCheckMark([])
                    setAllCheckMark(false)
                }
            }).catch((err) => console.log("Error:", err));
        } else {
            setalerts(prev => ({ ...prev, mdlone: true }))
        }
    }
    const handleapprovemodel = (autoid) => {
        setalerts(prev => ({ ...prev, autoid: autoid, mdltw: true }))
    }
    return (
        <div>
            <div className="h4_btm_bdr d-flex align-items-center">
                <div>
                    <h4 style={{ marginBottom: '0' }}>Customer Details</h4>
                </div>
                <div style={{ display: 'flex', gap: '10px', width: '60%', alignItems: 'center' }}>
                    {/* <select onChange={handleselectCustomertype} className="form-select" aria-label="Default select example" defaultValue="">
                        <option value="0">Select Customertype</option>
                        <option value="1">Individual</option>
                        <option value="2">Corporate</option>
                    </select> */}
                    <button onClick={Handleblukdownload} className='dwn_dn'>Download</button>
                    <select
                        onChange={(e) => dispatch(setPage({ CustomerStatus: e.target.value }))}
                        value={customerpagination.CustomerStatus}
                        className="form-select"
                        aria-label="Default select example"
                    >
                        <option value="0">Select CustomerStatus</option>
                        <option value="1">Pending For Approvel</option>
                        <option value="2">Pending</option>
                        <option value="3">Approved</option>
                        <option value="4">Rejected</option>
                    </select>
                    <input onChange={(e) => setcompanysearch(e.target.value)} className="search_inp_filed" type="text" name="" id="" placeholder="Search" value={customersearch}></input>
                    <i onClick={handlesearchCustomerstatus} className="fa-solid fa-magnifying-glass search_icon"></i>
                </div>
            </div>
            <div>
                <table className='table table_dv'>
                    <thead>
                        <tr>
                            <th></th>
                            <th>
                                <input style={{ verticalAlign: 'middle' }} type="checkbox" name="MailBox" id="All" checked={AllCheckMark} onChange={() => { setAllCheckMark(!AllCheckMark); handleMailCheck("ALL") }} />
                            </th>
                            <th>CustomerName</th>
                            <th>CustomerStatus</th>
                            <th>Contract No.</th>
                            <th>PAN Number</th>
                            <th>VehicleRegister No.</th>
                            <th>Vin No.</th>
                            <th>Rc Download</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            CustomerDate && CustomerDate.map((customerdata, id) => (
                                <tr key={id}>
                                    <td><p className="initial_round r1">{customerdata.customerName.charAt(0).toUpperCase()}</p></td>
                                    <td>
                                        {customerdata.filename != null && customerdata.filename != '' ? (
                                            <input
                                                type="checkbox"
                                                checked={MailCheckMark.includes(customerdata.customerAutoid)}
                                                name="MailBox2"
                                                id={customerdata.customerAutoid}
                                                onChange={() => handleMailCheck(customerdata.customerAutoid)}
                                            />
                                        ) : (
                                            ''
                                        )
                                        }
                                    </td>
                                    <td>{customerdata.customerName}</td>
                                    <td>{customerdata.customerStatus === 1 ? 'Pending For Approvel' : customerdata.customerStatus === 2 ?
                                        'pending' : customerdata.customerStatus === 3 ? 'approved' : customerdata.customerStatus === 4 ?
                                            'rejected' : ''}
                                    </td>
                                    <td>{customerdata.contractNo}</td>
                                    <td>{customerdata.pannumber}</td>
                                    <td>{customerdata.vehicleRegisterNo}</td>
                                    <td>{customerdata.vinno}</td>
                                    <td>{customerdata.filename != null ? <i style={{ cursor: 'pointer' }} onClick={() => handlefiledownload(customerdata.filename)} className="fa-solid fa-download"></i> : ''}</td>
                                    <td>
                                        {customerdata.customerStatus === 2 ? (
                                            <>
                                                <button
                                                    style={{
                                                        backgroundColor: customerdata.customerStatus === 3 ? 'Blue' : 'initial',
                                                    }}
                                                    onClick={() => handleapprove('Approved', customerdata.customerAutoid, null)}
                                                >
                                                    Approve
                                                </button>
                                                <button
                                                    style={{
                                                        backgroundColor: customerdata.customerStatus === 4 ? 'Red' : 'initial',
                                                    }}
                                                    className='ms-2'
                                                    onClick={() => handleapprovemodel(customerdata.customerAutoid)}
                                                >
                                                    Reject
                                                </button>
                                            </>
                                        ) : (
                                            ''
                                        )}
                                    </td>

                                </tr>
                            ))
                        }
                    </tbody>
                </table>
                <div className='mt-4 ps-1 pe-1' style={{ display: 'flex', alignItems: 'baseline', justifyContent: 'space-between' }}>
                    <div>
                        <p style={{ color: '#000', fontSize: '14px', marginBottom: '0px' }}>Total Record Count : <span style={{ fontWeight: '600' }}>{CustomerDateCount}</span></p>
                    </div>
                    <div>
                        <Pagination
                            pageNumbers={vrnmasterNumbers}
                            Paginations={customerpagination}
                            handlePageChange={UserhandlePageChange}
                            LastPage={CustomerDateCount && Math.ceil(CustomerDateCount / 10)}
                        />
                    </div>
                </div>
            </div>
            <Modal show={alerts.mdlone}>
                <Modal.Header>
                    <Modal.Title>Alert!</Modal.Title>
                </Modal.Header>
                <Modal.Body>Select data To Dowload</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setalerts(prev => ({ ...prev, mdlone: false }))}>
                        Ok
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={alerts.mdltw}>
                <Modal.Header>
                    <Modal.Title>Reason For Rejection</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='mdl_inp'>
                        <label htmlFor="R_F_R">Reason For Rejection</label>
                        <input type="text" value={alerts.rejection} id='R_F_R' onChange={(e) => setalerts(prev => ({ ...prev, rejection: e.target.value }))} />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => handleapprove('Rejected', alerts.autoid, alerts.rejection)}>
                        Ok
                    </Button>
                    <Button variant="secondary" onClick={() => setalerts(prev => ({ ...prev, mdltw: false, rejection: '' }))}>
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default VRN_master