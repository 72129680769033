import React, { useEffect, useState } from 'react';
import './Customer.css'
import MB_Logo from "../../Images/MB_Logo.png"
import { FetchCustomermaindata, setPage } from '../../Redux/Slices/Customermain';
import { useDispatch, useSelector } from 'react-redux';
import Pagination from '../Table_content/Pagination';
import AuthService from '../../Authservice';
import { Button, Modal } from 'react-bootstrap';

const Dashboard = () => {

    const dispatch = useDispatch();

    const { customermainpagination, CustomermainDateCount, CustomermainDate, loading, error } = useSelector((state) => state.customermain);
    const [decodevalue, setdecodevalue] = useState({ panumber: '', Identifier: '' })
    useEffect(() => {
        const decodeJWT = (token) => {
            if (token) {
                let tokenParts = token.split('.');
                let payload = JSON.parse(atob(tokenParts[1]));
                return payload;
            }
        }
        const decodedPayload = decodeJWT(AuthService.decryptData("JWT"));
        console.log(decodedPayload)
        setdecodevalue(prev => ({ ...prev, panumber: decodedPayload.Pannumber, Identifier: decodedPayload.Identifier }))
        if (decodedPayload) {
            dispatch(FetchCustomermaindata(decodedPayload.Pannumber));
        }
        // console.log('coming')
    }, [dispatch, customermainpagination.Current_page]);


    const [selectedOption, setSelectedOption] = useState('Option_1');

    const handleButtonClick = (option) => {
        setSelectedOption(option);
    };
    let InitialPasswordDetails = { oldPassword: "", newPassword: "", confirmPassword: "" }
    const [PasswordDetails, setPasswordDetails] = useState(InitialPasswordDetails);
    const [bgImg, setbgImg] = useState('Option_1');
    const [fileDetails, setFileDetails] = useState({});
    const [addbatchdet, setaddbatchdet] = useState({
        batchfilename: '',
        addbatcherrr: '',
        Responseerr: ''
    });
    const [contractValues, setContractValues] = useState({});
    const [Alert, setAlert] = useState({ modelone: false })
    const handleUpdate = () => {
        // Handle password update logic here
        if (!PasswordDetails.oldPassword) {
            alert('Old passwords is empty');
        }
        else if (PasswordDetails.oldPassword === PasswordDetails.newPassword) {
            alert("Old & New password are same")
        }
        else if (PasswordDetails.newPassword !== PasswordDetails.confirmPassword) {
            alert('New passwords do not match.');
        } else {
            changePassword({
                UserIdentifier: decodevalue.Identifier,
                OldPassword: PasswordDetails.oldPassword,
                NewPassword: PasswordDetails.newPassword
            });
        }
    };

    const handleCancel = () => {
        // Clear fields or perform cancel logic
        setPasswordDetails(InitialPasswordDetails);
    };


    const customermainNumbers = [];
    for (let i = 1; i <= Math.ceil(CustomermainDateCount && CustomermainDateCount / 10); i++) {
        customermainNumbers.push(i);
    }
    const CustomerhandlePageChange = (pageNumber) => {
        dispatch(setPage({ Current_page: pageNumber }));
    };
    // console.log(CustomermainDate)

    const handleFileChange = (e, id) => {
        const files = e.target.files;
        if (files.length > 0) {
            const validExtensions = ['xlsx', 'xls', 'pdf'];
            let validFiles = [];
            for (let i = 0; i < files.length; i++) {
                const file = files[i];
                console.log(file)
                const ext = file.name.split('.').pop().toLowerCase();
                if (validExtensions.includes(ext)) {
                    validFiles.push(file);
                } else {
                    setaddbatchdet((prevState) => ({
                        ...prevState,
                        addbatcherrr: 'Invalid file type. Only Excel or PDF files are allowed.'
                    }));
                    return; // Stop processing further if an invalid file is found
                }
            }
            // Store all valid files for the row
            setFileDetails((prevState) => ({
                ...prevState,
                [id]: validFiles
            }));
        }
    };
    const handleContractChange = (e, contractNo) => {
        const { value } = e.target;
        setContractValues((prevValues) => ({
            ...prevValues,
            [contractNo]: value, // Store or update the value for the corresponding contractNo
        }));
    };
    const handleupdatechsnumber = () => {
        let fd = new FormData();
        console.log(fileDetails)
        const combinedData = Object.keys(fileDetails).map((contractvalue) => ({
            Chasenumber: contractvalue,
            file: fileDetails[contractvalue],
            VechicleregNumber: contractValues[contractvalue] || "",
        }));
        console.log('Combined Data:', combinedData);
        const filtercombinedata = combinedData.filter(item => {
            return item.VechicleregNumber !== '' && item.file.length > 0;
        });
        console.log('Filtered Data:', filtercombinedata);
        filtercombinedata.forEach((item, index) => {
            fd.append(`fd[${index}].Chasenumber`, item.Chasenumber);
            fd.append(`fd[${index}].VechicleregNumber`, item.VechicleregNumber);

            if (item.file && item.file.length > 0) {
                fd.append(`fd[${index}].file`, item.file[0]);
            }
        });
        console.log(filtercombinedata)
        if (filtercombinedata.length > 0) {
            AuthService.PostServiceCallToken('api/Customer/UpdateCustomerVechicleREgisternumber', fd)
                .then((res) => {
                    if (res === "Success") {
                        dispatch(FetchCustomermaindata(decodevalue.panumber));
                    } else {
                        alert('error')
                    }
                })
                .catch((err) => {
                    console.error('API Error:', err);
                });
        } else {
            setAlert(prev => ({ ...prev, modelone: true }))
        }
    };
    const handleformcancel = () => {
        setFileDetails({});
        setContractValues({});
        document.getElementById('file_insert').value = ''
    }

    const Docustomerdelete = (val) => {
        console.log(val)
        AuthService.GetServiceCall(`api/Customer/DoDeletecustomer?val=${val}`)
            .then((res) => {
                console.log(res)
                if (res.status === "success") {
                    dispatch(FetchCustomermaindata(decodevalue.panumber));
                }
            })
    }
    console.log(CustomermainDate)
    let changePassword = (data) => {
        AuthService.PostServiceCallToken('api/User/ChangeOldPassword', data)
            .then((res) => {
                if (res.status === "success") {
                    alert("success")
                    setPasswordDetails(InitialPasswordDetails)
                } else if (res.status === "Invalid Old Password") {
                    alert("Invalid Old Password")
                }
            })
            .catch((err) => {
                console.error('API Error:', err);
            });
    }
    let onChangePasswordfunc = (e) => {
        let { value, name } = e.target
        setPasswordDetails(prev => ({ ...prev, [name]: value }))
    }
    return (
        <div>
            <div className='logo_section'>
                {/* <h3 className='mb-0'>Mercedes-Benz Financial Services</h3> */}
                <img src={MB_Logo} alt="" />
            </div>
            <div className="dashboard">
                <div className="sidebar">
                    <button onClick={() => handleButtonClick('Option_1')}>Vechile Registration Update</button>
                    <button onClick={() => handleButtonClick('Option_2')} className='btn2'>Change Password</button>
                </div>
                <div className={`result ${selectedOption}`}>
                    {selectedOption == "Option_1" ?
                        <div className='Registrattion_update'>
                            <h1 className='mb-5'>Welcome to Mercedes-Benz Financial Services</h1>
                            <div>
                                <table className="table table-bordered tbl_customer">
                                    <thead>
                                        <tr>
                                            <th>S.No</th>
                                            <th>Contract Number</th>
                                            <th>Asset Description</th>
                                            <th>Vin/Chassis No</th>
                                            <th>Vehicle Registration Number</th>
                                            <th>Upload Registration Certificate</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            CustomermainDate && CustomermainDate.map((customerdata, id) => (
                                                <tr key={id}>
                                                    <td>{id + 1}</td>
                                                    <td>{customerdata.contractNo}</td>
                                                    <td>{customerdata.assetDescription}</td>
                                                    <td>{customerdata.vinno}</td>
                                                    <td>
                                                        <input
                                                            type="text"
                                                            placeholder="Enter value"
                                                            value={contractValues[customerdata.vinno] || ""} // Bind the input to contractValues state
                                                            onChange={(e) => handleContractChange(e, customerdata.vinno)} // Update state when value changes
                                                        />
                                                    </td>
                                                    <td><input id='file_insert' type="file" onChange={(e) => handleFileChange(e, customerdata.vinno)} /> <span>{fileDetails[id]?.filename || ''}</span></td>
                                                    <td style={{ textAlign: 'center', cursor: 'pointer' }}><i onClick={() => Docustomerdelete(customerdata.customerAutoid)} className="fa-solid fa-trash"></i></td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </table>
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '20px' }}>
                                    <button onClick={handleupdatechsnumber}>Submit</button>
                                    <button onClick={handleformcancel}>Cancel</button>
                                </div>
                                <div className='mt-4 ps-1 pe-1' style={{ display: 'flex', alignItems: 'baseline', justifyContent: 'space-between' }}>
                                    <div>
                                        <p style={{ color: '#000', fontSize: '14px', marginBottom: '0px' }}>Total Record Count : <span style={{ fontWeight: '600' }}>{CustomermainDateCount}</span></p>
                                    </div>
                                    <div>
                                        <Pagination
                                            pageNumbers={customermainNumbers}
                                            Paginations={customermainpagination}
                                            handlePageChange={CustomerhandlePageChange}
                                            LastPage={CustomermainDateCount && Math.ceil(CustomermainDateCount / 10)}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div> :
                        <div className='Change_password'>
                            <div className='row'>
                                <div className="input-group">
                                    <div className='col-md-6 df_ac'>
                                        <label>Old Password *</label>
                                    </div>
                                    <div className='col-md-6'>
                                        <input type="password" value={PasswordDetails.oldPassword} name="oldPassword" onChange={onChangePasswordfunc} required />
                                    </div>
                                </div>
                                <div className="input-group">
                                    <div className='col-md-6 df_ac'>
                                        <label>New Password *</label>
                                    </div>
                                    <div className='col-md-6'>
                                        <input type="password" value={PasswordDetails.newPassword} name="newPassword" onChange={onChangePasswordfunc} required />
                                    </div>
                                </div>
                                <div className="input-group">
                                    <div className='col-md-6 df_ac'>
                                        <label>Confirm New Password *</label>
                                    </div>
                                    <div className='col-md-6'>
                                        <input type="password" value={PasswordDetails.confirmPassword} name="confirmPassword" onChange={onChangePasswordfunc} required />
                                    </div>
                                </div>
                            </div>

                            <div className="button-group">
                                <div className='row'>
                                    <div className='col-md-12 p-0'>
                                        <div className='btns'>
                                            <button onClick={handleUpdate} className="update-button">Update</button>
                                            <button onClick={handleCancel} className="cancel-button">Cancel</button>
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-md-12 p-0 mt-4'>
                                        <div className='password_policy'>
                                            <a href="#"><b>Password Policy</b></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
            <Modal show={Alert.modelone}>
                <Modal.Header>
                    <Modal.Title>Alert!</Modal.Title>
                </Modal.Header>
                <Modal.Body>Fill Atleast One row Vehicle Registration Number and upload file</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setAlert(prev => ({ ...prev, modelone: false }))}>
                        Ok
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default Dashboard;
